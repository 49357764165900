import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import Autos2050Logo from "../images/logos/Autos2050_Logo_Small.png"

function Nominations() {
  const [secondary, setSecondary] = useState("noSecondary")

  const onValueChange = e => {
    setSecondary(e.target.value)
  }

  return (
    <Layout>
      <Seo title="Nominations" />
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col items-center justify-center py-32 w-full min-h-screen space-y-8"
      >
        <div className="container relative mx-auto px-4 py-8 w-10/12 bg-gray-100 border-b-8 border-secondary-200">
          <img
            src={Autos2050Logo}
            alt="Autos2050 Logo"
            className="m-4 w-32 lg:w-40"
          />
          <form
            name="Nominations"
            method="POST"
            data-netlify="true"
            action="/success"
            className="p-4 w-full bg-white"
          >
            <input type="hidden" name="form-name" value="Nominations" />
            <h1 className="mb-4 font-bold">The 2050 Award Nomination</h1>
            <div className="flex flex-wrap justify-between">
              <div className="max-w-2xl space-y-4">
                <div>
                  <h3 className="mb-2 text-sm font-bold">
                    I'm submitting a nomination for the:
                  </h3>
                  <label className="block">
                    <input
                      type="radio"
                      value="impact"
                      name="AwardType"
                      className="mr-2"
                    />
                    Impact Award
                  </label>
                  <label className="block">
                    <input
                      type="radio"
                      value="ingenuity"
                      name="AwardType"
                      className="mr-2"
                    />
                    Ingenuity Award
                  </label>
                </div>

                <div>
                  <h2 className="font-bold">Nominee's Contact Information</h2>
                  <hr />
                </div>
                <div>
                  <h3 className="mb-2 text-sm font-bold">Nominee's Name</h3>
                  <div className="flex flex-col text-sm space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                    <label htmlFor="NomineeSalutation">
                      <div>Salutation</div>

                      <input
                        type="text"
                        name="NomineeSalutation"
                        placeholder="Salutation"
                        className="px-2 border-2 border-gray-300 rounded-sm md:w-full"
                      />
                    </label>
                    <label htmlFor="NomineeFirstName">
                      <div>First Name</div>

                      <input
                        type="text"
                        name="NomineeFirstName"
                        placeholder="First Name"
                        className="px-2 border-2 border-gray-300 rounded-sm md:w-full"
                      />
                    </label>
                    <label htmlFor="NomineeMiddleInitial">
                      <div>Middle Initial</div>

                      <input
                        type="text"
                        name="NomineeMiddleInitial"
                        placeholder="Middle Initial"
                        className="px-2 border-2 border-gray-300 rounded-sm md:w-full"
                      />
                    </label>
                    <label htmlFor="NomineeLastName">
                      <div>Last Name</div>

                      <input
                        type="text"
                        name="NomineeLastName"
                        placeholder="Last Name"
                        className="px-2 border-2 border-gray-300 rounded-sm md:w-full"
                      />
                    </label>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="NomineeJobTitle"
                    className="block mb-2 text-sm font-bold"
                  >
                    Nominee's Job Title*
                  </label>
                  <input
                    type="text"
                    name="NomineeJobTitle"
                    placeholder="Nominee's Job Title"
                    className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                  />
                </div>
                <div>
                  <label
                    htmlFor="NomineeCompany"
                    className="block mb-2 text-sm font-bold"
                  >
                    Nominee's Company*
                  </label>
                  <input
                    type="text"
                    name="NomineeCompany"
                    placeholder="Nominee's Company"
                    className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                  />
                </div>
                <div>
                  <label
                    htmlFor="NomineeEmailAddress"
                    className="block mb-2 text-sm font-bold"
                  >
                    Nominee's Email Address*
                  </label>
                  <input
                    type="email"
                    name="NomineeEmailAddress"
                    placeholder="Nominee's Email Address"
                    className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                  />
                </div>
                <div>
                  <label
                    htmlFor="NomineePhone"
                    className="block mb-2 text-sm font-bold"
                  >
                    Nominee's Phone*
                  </label>
                  <input
                    type="tel"
                    name="NomineePhone"
                    placeholder="Nominee's Phone"
                    className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                  />
                </div>
                <div>
                  <h2 className="font-bold">About the Nominee</h2>
                  <hr />
                </div>
                <div>
                  <label
                    htmlFor="NomineeEmploymentSector"
                    className="block mb-2 text-sm font-bold"
                  >
                    Nominee's Employment Sector
                  </label>
                  <input
                    type="text"
                    name="NomineeEmploymentSector"
                    placeholder="Nominee's Employment Sector"
                    className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                  />
                </div>
                <div>
                  <label
                    htmlFor="NomineeWork"
                    className="block mb-2 text-sm font-bold"
                  >
                    Describe the nominee's job and the work for which he or she
                    is being nominated and how they demonstrated a particularly
                    strong vision for innovation and change.*
                  </label>
                  <p className="mb-4 text-gray-600 text-xs">
                    please limit response to 1,200 characters, including spaces
                  </p>
                  <textarea
                    name="NomineeWork"
                    className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm"
                    cols="30"
                    rows="10"
                  ></textarea>
                  <p className="text-gray-500 text-xs">
                    0 of 1200 max characters
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="NomineeLeadership"
                    className="block mb-2 text-sm font-bold"
                  >
                    Describe how the individual or organization being nominated
                    has demonstrated exceptional leadership in advancing the
                    goals of the industry.
                  </label>
                  <p className="mb-4 text-gray-600 text-xs">
                    please limit response to 1,200 characters, including spaces
                  </p>
                  <textarea
                    name="NomineeLeadership"
                    className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm"
                    cols="30"
                    rows="10"
                  ></textarea>
                  <p className="text-gray-500 text-xs">
                    0 of 1200 max characters
                  </p>
                </div>
              </div>

              <div className="max-w-2xl space-y-4">
                <div>
                  <h2 className="font-bold">Nominator Details</h2>
                  <p className="text-gray-600 text-xs">
                    Your role as a nominator is an important aspect of the
                    judging process. Please complete the fields below. Two
                    nominators can be submitted for each nominee. Please ensure
                    that all nominators are aware that they are being included
                    and documented as part of this process. Upon completion and
                    submission of the nomination, a confirmation email will be
                    sent to the primary nominator.
                  </p>
                  <hr />
                </div>
                <div>
                  <h2 className="font-bold">Primary Nominator</h2>
                  <hr />
                </div>
                <div className="space-y-4">
                  <h3 className="mb-2 text-sm font-bold">
                    Primary Nominator's Name*
                  </h3>
                  <div className="flex text-sm space-x-4">
                    <label htmlFor="NominatorFirstName">
                      First Name
                      <input
                        type="text"
                        name="NominatorFirstName"
                        placeholder="First Name"
                        className="px-2 w-full border-2 border-gray-300 rounded-sm"
                      />
                    </label>
                    <label htmlFor="NominatorLastName">
                      Last Name
                      <input
                        type="text"
                        name="NominatorLastName"
                        placeholder="Last Name"
                        className="px-2 w-full border-2 border-gray-300 rounded-sm"
                      />
                    </label>
                  </div>
                  <div>
                    <label
                      htmlFor="NominatorJobTitle"
                      className="block mb-2 text-sm font-bold"
                    >
                      Primary Nominator's Job Title*
                    </label>
                    <input
                      type="text"
                      name="NominatorJobTitle"
                      placeholder="Primary Nominator's Job Title"
                      className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="NominatorCompany"
                      className="block mb-2 text-sm font-bold"
                    >
                      Primary Nominator's Company/Employer*
                    </label>
                    <input
                      type="text"
                      name="NominatorCompany"
                      placeholder="Primary Nominator's Company/Employer"
                      className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="NominatorEmail"
                      className="block mb-2 text-sm font-bold"
                    >
                      Primary Nominator's Email*
                    </label>
                    <input
                      type="email"
                      name="NominatorEmail"
                      placeholder="Primary Nominator's Email"
                      className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="NominatorPhone"
                      className="block mb-2 text-sm font-bold"
                    >
                      Primary Nominator's Phone*
                    </label>
                    <input
                      type="tel"
                      name="NominatorPhone"
                      placeholder="Primary Nominator's Phone"
                      className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                    />
                  </div>
                  <div>
                    <h3 className="mb-2 text-sm font-bold">
                      Is there a supporting nominator?
                    </h3>
                    <label htmlFor="NominatorNum" className="block">
                      <input
                        type="radio"
                        value="noSecondary"
                        name="NominatorNum"
                        checked={secondary === "noSecondary"}
                        onChange={onValueChange}
                        className="mr-2"
                      />
                      No, I am the only nominator
                    </label>
                    <label htmlFor="NominatorNum" className="block">
                      <input
                        type="radio"
                        value="secondary"
                        name="NominatorNum"
                        className="mr-2"
                        checked={secondary === "secondary"}
                        onChange={onValueChange}
                      />
                      Yes, there is a secondary, supporting nominator
                    </label>
                  </div>
                  {secondary === "secondary" && (
                    <div className="space-y-4">
                      <div>
                        <h2 className="font-bold">Secondary Nominator</h2>
                        <hr />
                      </div>
                      <h3 className="mb-2 text-sm font-bold">
                        Secondary Nominator's Name*
                      </h3>
                      <div className="flex text-sm space-x-4">
                        <label htmlFor="SecNominatorFirstName">
                          First Name
                          <input
                            type="text"
                            name="SecNominatorFirstName"
                            placeholder="First Name"
                            className="px-2 w-full border-2 border-gray-300 rounded-sm"
                          />
                        </label>
                        <label htmlFor="NominatorLastName">
                          Last Name
                          <input
                            type="text"
                            name="SecNominatorLastName"
                            placeholder="Last Name"
                            className="px-2 w-full border-2 border-gray-300 rounded-sm"
                          />
                        </label>
                      </div>
                      <div>
                        <label
                          htmlFor="SecNominatorJobTitle"
                          className="block mb-2 text-sm font-bold"
                        >
                          Secondary Nominator's Job Title*
                        </label>
                        <input
                          type="text"
                          name="SecNominatorJobTitle"
                          placeholder="Secondary Nominator's Job Title"
                          className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="SecNominatorCompany"
                          className="block mb-2 text-sm font-bold"
                        >
                          Secondary Nominator's Company/Employer*
                        </label>
                        <input
                          type="text"
                          name="SecNominatorCompany"
                          placeholder="Secondary Nominator's Company/Employer"
                          className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="SecNominatorEmail"
                          className="block mb-2 text-sm font-bold"
                        >
                          Secondary Nominator's Email*
                        </label>
                        <input
                          type="email"
                          name="SecNominatorEmail"
                          placeholder="Secondary Nominator's Email"
                          className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="SecNominatorPhone"
                          className="block mb-2 text-sm font-bold"
                        >
                          Secondary Nominator's Phone*
                        </label>
                        <input
                          type="tel"
                          name="SecNominatorPhone"
                          placeholder="Secondary Nominator's Phone"
                          className="px-2 w-full text-sm border-2 border-gray-300 rounded-sm md:w-80"
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <h2 className="font-bold">Stay Connected</h2>
                    <hr />
                  </div>
                  <div>
                    <h3 className="mb-2 text-sm font-bold">
                      Would you like to receive information about Autos2050 and
                      the 2050 Awards in the future
                    </h3>
                    <label htmlFor="ReceiveInformation" className="block">
                      <input
                        type="radio"
                        value="Yes"
                        name="ReceiveInformation"
                        checked={true}
                        className="mr-2"
                      />
                      Yes
                    </label>
                    <label htmlFor="ReceiveInformation" className="block">
                      <input
                        type="radio"
                        value="No"
                        name="ReceiveInformation"
                        className="mr-2"
                      />
                      No
                    </label>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    type="submit"
                    className="border-2
						font-bold
						text-black
						border-black
						py-2
						px-4
						rounded-full
						min-w-150
						transition
						focus:outline-none
						hover:bg-black
						hover:text-white"
                  >
                    SUBMIT
                  </button>
                  <a
                    href="https://www.autosinnovate.org/privacy-policy"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:text-gray-600 underline"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </motion.section>
    </Layout>
  )
}

export default Nominations
